<template>
<div class="mobile-menu-container">
    <div class="mobile-menu-wrapper">
        <span class="mobile-menu-close" @click="hideMobileMenu">
            <i class="icon-close"></i>
        </span>
        <div class="cust-data" v-if="(customerSettingDetails && customerSettingDetails.secure_status !== 'A') || ( customerDetails && customerDetails.profile_status !== 'A') || $store.state.sessionStorage.login_successfull">
            <h3 class="summary-title-mob">{{customerMembershipDetails.group_name}}</h3>
            <p class="pre-des-mob">{{customerDetails.customer_first_name}}</p>
            <p v-if="customerDetails.customer_email" class="pre-des-mob">{{customerDetails.customer_email}}</p>
            <p v-if="customerDetails.customer_mobile" class="pre-des-mob">{{customerDetails.customer_mobile}}</p>
            <!-- <img class="qr-img" :src="walletQR ? walletQR : 'https://mzcoupon.s3.ap-southeast-1.amazonaws.com/coupon/qrcode/b69317aeed811d97e05888b5bc7da8fe_customer_id_qrcode.png'" alt="" style="margin-bottom:1em; height: 231px; width: 232px; object-fit: contain;"> -->
            <div v-if="!walletQrShow" >
                <img :src="customer_group_image" alt="" style="margin-bottom:1em; height: 232px; object-fit: contain; margin-top:1em;">
            </div>
            <div id="Qr-code" style="margin-top:1em" v-else>
                <img :src="walletQR" alt="" style="margin-bottom:1em; height: 232px; object-fit: contain; ">
            </div>
            <button v-if="!walletQrShow" id="mob-btn" type="submit" class="btn btn-outline-primary-2 btn-order btn-block" @click.prevent="ViewWalletQR()">
                <span class="btn-text">{{$t("components.mobilemenu.view_wallet_qr")}}</span>
                <span class="btn-hover-text">{{$t("components.mobilemenu.view_wallet_qr")}}</span>
            </button>
            <button v-else type="submit" class="btn btn-outline-primary-2 btn-order btn-block" id="mob-btn" @click.prevent="CloseWalletQR()">
                <span class="btn-text">{{$t("components.mobilemenu.close_wallet_qr")}}</span>
                <span class="btn-hover-text">{{$t("components.mobilemenu.close_wallet_qr")}}</span>
            </button>
            <button v-if="customerSettingDetails.secure_status === 'A' && !$store.state.sessionStorage.login_successfull" type="submit" class="btn btn-outline-primary-2 btn-order btn-block" id="mob-btn" @click.prevent="updateCustomer()">
                <span class="btn-text">{{$t('page.product_listing.secure_wallet')}}</span>
                <span class="btn-hover-text">{{$t('page.product_listing.secure_wallet')}}</span>
            </button>
        </div>
        <nav class="mobile-nav" >

            <ul class="mobile-menu">
                <div v-if="(customerSettingDetails && customerSettingDetails.secure_status !== 'A') || ( customerDetails && customerDetails.profile_status !== 'A') || $store.state.sessionStorage.login_successfull">
                <li >
                    <a href="#" class="sf-with-ul" @click="activeCoupon()">{{$t("components.mobilemenu.coupon_active")}}  <span class="item-count" >{{ activeCount}}</span></a>
                </li >
                <li v-if="processingcount > 0">
                    <a href="#" class="sf-with-ul" @click="historyCoupon('B')">{{ $t("components.shop_side_bar_one.coupon_processing")}} <span class="item-count" >{{ processingcount }}</span></a>
                </li>
                <li>
                    <a href="#" class="sf-with-ul" @click="historyCoupon('T')">{{$t("components.mobilemenu.coupon_history")}} <span class="item-count" >{{ historycount}}</span></a>
                </li>
                <li>
                    <a href="#" class="sf-with-ul" @click="historyCoupon('E')">{{$t("components.mobilemenu.coupon_expired")}} <span class="item-count">{{ expirycount}}</span></a>
                </li>
                </div>
                <!-- <li >
                        <nuxt-link to="#" class="sf-with-ul">Transcation</nuxt-link>
                    </li> -->
                <li>
                    <a class="sf-with-ul languageClass" style="color:white;">{{$t("components.mobilemenu.language")}}</a>

                    <ul>
                        <li v-for="(lang, index) of selectedLang" :key="index">
                            <a href="#" @click.prevent="lang_change(lang.code)">{{lang.name}}</a>
                        </li>

                    </ul>
                </li>

            </ul>
        </nav>
        <div class="container cust-data" v-if="customerSettingDetails.secure_status === 'A' && customerDetails.profile_status === 'A' && $store.state.sessionStorage.login_successfull" >
            <button  id="mob-btn" type="submit" class="btn btn-outline-primary-2 btn-order btn-block" @click.prevent="logout()">
                        <span class="btn-text">{{ $t("components.user_menu.sign_out") }}</span>
                        <span class="btn-hover-text">{{ $t("components.user_menu.sign_out") }}</span>
                    </button>
        </div>
            <!-- <div class="container cust-data" style="margin-top:-34px"
                v-if="customerSettingDetails.secure_status === 'A' && customerDetails.profile_status === 'A' && $store.state.sessionStorage.login_successfull">
                <button id="mob-btn" type="submit" class="btn btn-outline-primary-2 btn-order btn-block "
                    @click.prevent="updatePassword()">
                    <span class="btn-text">{{ $t('page.forgot.change_password') }}</span>
                    <span class="btn-hover-text">{{ $t('page.forgot.change_password') }}</span>
                </button>
            </div> -->
        
        <div class="social-icons">
            <a class="social-icon" :href="merchantDetails.facebook_url" target="_blank" title="Facebook" v-if="merchantDetails.facebook_url !== null && merchantDetails.facebook_url !== ''">
                <i class="icon-facebook-f"></i>
            </a>
            <a class="social-icon" :href="merchantDetails.twitter_url" target="_blank" title="Twitter" v-if="merchantDetails.twitter_url !== null && merchantDetails.twitter_url !== ''">
                <i class="icon-twitter"></i>
            </a>
            <a class="social-icon" :href="merchantDetails.instagram_url" target="_blank" title="Instagram" v-if="merchantDetails.instagram_url !== null && merchantDetails.instagram_url !== ''">
                <i class="icon-instagram"></i>
            </a>
            <a class="social-icon" :href="merchantDetails.youtube_url" target="_blank" title="Youtube" v-if="merchantDetails.youtube_url !== null && merchantDetails.youtube_url !== ''">
                <i class="icon-youtube"></i>
            </a>
        </div>
    </div>
</div>
</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';
import Tabs from '~/components/elements/Tabs';
import { mobileMenu } from '~/utilities/common.js';

export default {
    data: function () {
        return {
            searchTerm: '',
            MerchantSettings: '',
            selectedLang: '',
            merchantDetails: '',
            customerDetails: '',
            walletQR: '',
            walletQrShow: false,
            customerMembershipDetails: '',
            customerSettingDetails: '',
            customer_group_image: null,
            activeCount: '',
            historycount: '',
            expirycount: '',
            Count: '',
            environment: '',
            Serialdetails:'',
            processingcount: '',
            msal: null
        };
    },
    mounted: function () {
        this.environment = this.$store.state.sessionStorage.environment
        this.customer_id = this.$route.params.id;
        console.log("customer_id##1", this.customer_id)
        console.log("mobile menu");
        
        mobileMenu();
        this.GetCustomer();
        this.merchantSettings();
        this.getMerchantDetails();
       
        this.getCustomerSetting();
        this.getCoupons();
        // this.GetTransferSerialslist()
        this.getHistoryCoupons();
        this.getexpiryCoupons();
        this.getprocessingCoupons()
        // if (this.$store.state.sessionStorage.customerDetails) {
        //     this.customerDetails = this.$store.state.sessionStorage.customerDetails;
        //     console.log("customerDetails",this.customerDetails)
        //     this.walletQR = this.customerDetails.customer_image_url;
        // }

    },
    methods: {
                                  //<---------------- logout Function ------------------>

        logout: function () {
             if (this.$store.state.sessionStorage.GenericLogin) {
                if (this.msal) {
                    this.msal.logout();
                }
                window.location.replace('/')
                this.$store.commit("sessionStorage/assignuserLoginSuccessfull", false);
                this.$store.commit('sessionStorage/assignParamsCustomerID', null);
            } else {
                if (this.msal) {
                    this.msal.logout();
                }
                this.$store.commit("sessionStorage/assignuserLoginSuccessfull", false);
            }
	    
	    
            

        },
        updatePassword() {
                // this.$store.commit("sessionStorage/assignuserLoginSuccessfull", false);
                window.location.replace('/updatepassword')
        },
           msalConfig(customerSettingDetails) {
            console.log("customerSettingDetails", customerSettingDetails);
            if (customerSettingDetails) {
                const msalConfig = {
                    auth: {
                        clientId: customerSettingDetails.client_id,
                        authority: `https://${customerSettingDetails.tenant_name}.b2clogin.com/${customerSettingDetails.tenant_name}.onmicrosoft.com/${customerSettingDetails.user_flow_name}`,
                        knownAuthorities: [`${customerSettingDetails.tenant_name}.b2clogin.com`],
                        redirectUri: customerSettingDetails.azure_redirect_url //customerSettingDetails.azure_redirect_url,
                    },
                    cache: {
                        cacheLocation: 'localStorage',
                        storeAuthStateInCookie: true,
                    },
                };

                const msalInstance = new PublicClientApplication(msalConfig);
                console.log('MSAL instance directly in component:', msalInstance);
                this.msal = msalInstance;
            } else {
                console.error('customerSettingDetails is not available');
            }
        },
        // startScanner() {
        //     this.scannerCode = true;
        // },
        // stopScanner() {
        //     if (this.$refs.scanner && this.$refs.scanner.stop) {
        //         this.$refs.scanner.stop();
        //     }
        //     this.scannerCode = false;
        // },
        // onDecode(decodedString) {
        //      var encrypt = this.$route.query.encrypted ? this.$route.query.encrypted : 'N'
        //     var couponserial = decodedString
        //     var url = `/api/mzapps/serialdetails/${couponserial}?customer_id=${this.customer_id}&timestamp=${new Date().getTime()}&encrypted=${encrypt}`
        //     this.$axios.$get(url).then(response => {
        //         this.Serialdetails = response.couponserial;
        //         this.quickView()
        //         this.hideMobileMenu()
        //         console.log("transfer_count!!!!!", this.Serialdetails);

        //     }).catch(e => {
        //         console.log("error@@@@", e);
        //         var error_params = {
        //             msg: e.data ? e.data.message : e,
        //             api: 'serialcount Api',
        //             level: 'error'
        //         }
        //         var error_text = this.faroLog(error_params)
        //         faro.api.pushError(new Error(error_text))
        //         if (e.data.message) {
        //             var data = e.data.message
        //         } else {
        //             var data = 'Sorry, Invalid Coupon Serial!.'
        //         }
        //         alert(`${data}`)
        //     })
        //     this.stopScanner();
        // },
        // onInit(promise) {
        //     promise
        //         .then(() => {
        //             console.log('Scanner initialized');
        //         })
        //         .catch(error => {
        //             this.scannerCode = false;
        //             alert(`${error.message}`);
        //             // document.getElementById("modals-container").style.display = "block"
                   
        //             console.error('Scanner initialization error:', error);
        //         });
        // },
        // quickView: function () {
        //     this.$modal.show(
        //         () => import('~/components/elements/modals/ScannerViewModal'), {
        //             products: this.Serialdetails,
        //             merchant_details: this.merchantDetails
        //     }, { width: '1030', height: 'auto', adaptive: true }
        //     );
        // },
        faroLog(data) {
            var message = "application:" + "Mz-WALLET, environment:" + `${this.environment}` + ", level:" + `${data.level}` + ", module:" + `CartMenu Page (${data.api})` + ", msg:" + `${data.msg}` + ", time:" + `${new Date()}`
            return message
        },
        GetTransferSerialslist: function () {
            var url = `/api/mzapps/serialcount/${this.customer_id}?timestamp=${new Date().getTime()}`
            this.$axios.$get(url).then(response => {
                console.log("Count@@@", this.Count);
                this.activeCount = response.customer_serial_counts.total_serial_count;
                console.log("transfer_count!!!!!", this.activeCount);
                console.log("GetTransferSerialslist", response);

            }).catch(e => {
                this.activeCount = 0
                console.log("error@@@@", e);
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'serialcount Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },

        historyCoupon: function (status) {
            
            this.$store.commit('sessionStorage/assignListType', status);

        },

        activeCoupon: function (id) {
           
            this.$store.commit('sessionStorage/assignListType', 'A');
        },

        //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Coupon list api function >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
           getCoupons: async function () {
            var url = `/api/mzapps/couponlist/${this.customer_id}?page=1&perpage=5&sort=+coupon_name&timestamp=${new Date().getTime()}`;
            await this.$axios.$get(url).then(response => {
                // this.activeCount = response.summary.totalsize;
                this.Count = response.summary.totalsize
                this.GetTransferSerialslist()

            }).catch(e => {
                this.activeCount = 0
                this.GetTransferSerialslist()
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'couponlist Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },

         //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< history Coupon list api function >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
       getHistoryCoupons: function () {
        console.log("getHistoryCoupons");
            var url = `/api/mzapps/history/couponlist/${this.customer_id}?page=1&perpage=5&sort=+coupon_name&list_type=T&timestamp=${new Date().getTime()}`;
            this.$axios.$get(url).then(response => {
                this.historycount = response.summary.totalsize;

            }).catch(e => {
                console.log("error", e);
                this.historycount = 0
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'history couponlist Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },

        getexpiryCoupons: function () {
        console.log("getHistoryCoupons");
            var url = `/api/mzapps/history/couponlist/${this.customer_id}?page=1&perpage=5&sort=+coupon_name&list_type=E&timestamp=${new Date().getTime()}`;
            this.$axios.$get(url).then(response => {
                this.expirycount = response.summary.totalsize;  

            }).catch(e => {
                console.log("error", e);
                this.expirycount = 0
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'history couponlist Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },
        getprocessingCoupons: function () {
            console.log("getHistoryCoupons");
            var url = `/api/mzapps/history/couponlist/${this.customer_id}?page=1&perpage=5&sort=+coupon_name&list_type=B&timestamp=${new Date().getTime()}`;
            this.$axios.$get(url).then(response => {
                this.processingcount = response.summary.totalsize;

            }).catch(e => {
                console.log("error", e);
                this.processingcount = 0
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'history couponlist Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },

        //  <=============================== Get Customer Setting API function for wallet ==============================>>>>

        getCustomerSetting: function () {
            var url = `/api/mzapps/customersetting?timestamp=${new Date().getTime()}`;
            this.$axios.$get(url).then(response => {
                this.customerSettingDetails = response.customer_setting;
                console.log("customerSettingDetails", this.customerSettingDetails);
                if (this.customerSettingDetails) {
                    this.msalConfig(this.customerSettingDetails)
                    console.log("this.customerSettingDetails", this.customerSettingDetails);
                }
            }).catch(e => {
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'customersetting Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },
        //<---------------------------- customer details api function ----------------------------->

        async GetCustomer() {
            var customerId = this.customer_id;
            // console.log('customer_id',customerId);
            await this.$axios.$get(`/api/mzapps/customer/${customerId}?timestamp=${new Date().getTime()}`).then(response => {
                console.log('customer respponse', response);
                this.customerDetails = response;
                console.log("customerDetails", this.customerDetails)
                var customerGroupId = this.customerDetails.customer_group_id

                this.getCustomerMembership(customerGroupId)
            }).catch(e => {
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'customer Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
                // console.log("customer Error ", error);
            })

        },

        //<------------------------------ customer membership api function --------------------->
        getCustomerMembership: function (customerGroupId) {
            var url = `/api/mzapps/membership/${customerGroupId}?timestamp=${new Date().getTime()}`;
            console.log("membership url", url);
            this.$axios.$get(url).then(response => {
                this.customerMembershipDetails =  this.$store.state.sessionStorage.customerMembershipDetails;
                this.customer_group_image = this.customerMembershipDetails.group_image ? this.customerMembershipDetails.group_image : this.merchantDetails.merchant_logourl
                this.walletQR = this.customerMembershipDetails.group_image;
                console.log("customerMembershipDetails mobile menu", this.customerMembershipDetails);
            }).catch(e => {
                this.customer_group_image = this.merchantDetails.merchant_logourl;
                console.log("getCustomerMembership error", e)
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'membership Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },

        hideMobileMenu: function () {
            document.querySelector('body').classList.remove('mmenu-active');
        },
        submitSearchForm: function () {
            this.hideMobileMenu();
            this.$router.push({
                path: '/shop/sidebar/3cols',
                query: {
                    searchTerm: this.searchTerm
                }
            });
        },

        ViewWalletQR() {
            console.log();
            this.walletQR = this.customerDetails.encrypt_customer_id_url;
            this.walletQrShow = true;

        },
        CloseWalletQR() {
            this.walletQrShow = false;
        },

        updateCustomer() {
            console.log('this.$router.query', this.$route.query);
            var url = `/updatecustomer`
            console.log("url", url);
            window.location.replace(url)
        },

        // <================================ Merchant Settings Api function ====================================>

        merchantSettings: function () {
            var url = `/api/mzapps/settings?country_code=${this.country}&timestamp=${new Date().getTime()}`
            this.$axios.$get(url).then(response => {
                console.log("merchantSettings ", response)
                this.MerchantSettings = response;
                if (this.MerchantSettings) {
                    if (this.MerchantSettings.selected_language) {
                        var selected_language = this.MerchantSettings.selected_language;
                        var selectLanguage = [];
                        if (selected_language.kr) {
                            var language_object_kr = {};
                        }
                        if (selected_language.en) {
                            var language_object_en = {};
                        }
                        if (selected_language.zh_Hant) {
                            var language_object_zh_Hant = {};
                        }
                        if (selected_language.zh_Hans) {
                            var language_object_zh_Hans = {};
                        }
                        if (selected_language.id) {
                            var language_object_id = {};
                        }
                        if (selected_language.th) {
                            var language_object_th = {};
                        }
                        if (selected_language.ja) {
                            var language_object_ja = {};
                        }
                        if (selected_language.kr) {
                            language_object_kr.name = "Korean (한국어)";
                            language_object_kr.code = "kr";
                            language_object_kr.code_align = "F";
                            selectLanguage.push(language_object_kr);
                        }

                        if (selected_language.en) {
                            language_object_en.name = "English";
                            language_object_en.code = "en";
                            language_object_en.code_align = "A";
                            selectLanguage.push(language_object_en);
                        }

                        if (selected_language.zh_Hant) {
                            language_object_zh_Hant.name = "Chinese Traditional (繁體中文)";
                            language_object_zh_Hant.code = "zh_Hant";
                            language_object_zh_Hant.code_align = "C";
                            selectLanguage.push(language_object_zh_Hant);
                        }

                        if (selected_language.zh_Hans) {
                            language_object_zh_Hans.name = "Chinese Simplified (簡體中文)";
                            language_object_zh_Hans.code = "zh_Hans";
                            language_object_zh_Hans.code_align = "D";
                            selectLanguage.push(language_object_zh_Hans);
                        }

                        if (selected_language.id) {
                            language_object_id.name = "Bahasa Indonesia";
                            language_object_id.code = "id";
                            language_object_id.code_align = "B";
                            selectLanguage.push(language_object_id);
                        }

                        if (selected_language.th) {
                            language_object_th.name = "Thai (ภาษาไทย)";
                            language_object_th.code = "th";
                            language_object_th.code_align = "G";
                            selectLanguage.push(language_object_th);
                        }

                        if (selected_language.ja) {
                            language_object_ja.name = "Japanese (日本語)";
                            language_object_ja.code = "ja";
                            language_object_ja.code_align = "E";
                            selectLanguage.push(language_object_ja);
                        }

                        this.selectedLang = selectLanguage;
                        this.selectedLang.sort((a, b) => a.code_align.localeCompare(b.code_align));
                    }
                }

                console.log('this.selectedLang', this.selectedLang);
            }).catch(e => {
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'settings Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },

        // <<<<<<<<<<<<<<<<<<<<<<< language change function >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        lang_change: function (lang_code) {
            this.$store.commit('sessionStorage/assignSelectLang', lang_code);
            window.location.reload()
            // MultiLang(lang_code)
        },

        // <================================ Merchant Detail Api function ====================================>

        getMerchantDetails: function () {
            var url = `/api/mzapps/merchant/${this.$store.state.sessionStorage.merchant_id}?country_code=${this.country}&timestamp=${new Date().getTime()}`
            this.$axios.$get(url).then(response => {
                this.merchantDetails = response.merchant;
                console.log('FoootermerchantDetails', this.merchantDetails);
                // document.title = `Official E-Voucher Store | ${this.merchantDetails.merchant_name}`
            }).catch(e => {
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'merchant Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },
    }
};
</script>

<style>
.cust-data {
    padding: 2em !important;
}

.pre-des-mob {
    word-wrap: break-word !important;
    color: #fff !important;
}

.summary-title-mob {
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: 0;
    padding-bottom: 0 !important;
    border-bottom: none !important;
    margin-bottom: 0.5rem !important;
    color: #fff !important;
}

#mob-btn {
    border-color: #fff !important;
    color: #fff !important;
}

#mob-btn:hover {
    border-color: #fff !important;
    color: #fff !important;
    background-color: #333333 !important;
}

.qr-img {
    padding: 2em !important;
}
.item-count{
    display: flex;
    align-items: center;
    justify-content: center;
    right: 1rem;
    top: 50%;
    z-index: 10;
    width: 3rem;
    height: 3rem;
    font-size: 1.2rem;
    color: #fff;
    margin-top: -1.5rem;
    
   
   
}
.mobile-menu span:not(.mmenu-btn):not(.tip) {
    position: absolute !important;
}
</style>
